const LANGUAGE = {
  eng: {
    intro: {
      top: `United Business Travel Solutions`,
      bottom: `Meetings. Incentives. Conferences. Exhibitions`,
    },
    firstTitle: {
      title:`Welcome to UVS, a leading and engaging space for those seeking high-quality services in business meetings, business event creation, and business travel.`,
      body: `UVS is a modern company that offers a full range of services, works with partners worldwide, and creates an effective experience for business owners and their companies. Thanks to our innovative solutions, we aim to save your time and provide additional opportunities for growth and success. By trusting UVS, you will receive high-quality service, a personalized approach to each client, and a reliable partner that will help you achieve your business goals and unlock the full potential of your company.`,
      firstSubsection: `We understand the importance of creating meaningful connections and establishing long-term relationships. Our mission is to facilitate positive dialogue between you and the surrounding world.`,
      secondarySubsection: `We offer long-term cooperation on a turnkey basis. You can get more detailed information about cooperation by contacting us.`,
    },
    singleText: `At UVS, we firmly believe in the power of collaboration and open dialogue. We invite you to collaborate and experience the effective support of UVS at every step.`,
    centerText: {
      top: `We offer turnkey long-term cooperation.`,
      bottom: `For more detailed information about our collaboration opportunities, please contact us.`,
    },
    cardProduct: {
      title: `Explore some of our services`,
      card1: {
        title: `BUSINESS MEETINGS`,
        body: [
          "Meeting organization",
          "Individual and corporate",
          "",
          "BUSINESS SUPPORT:",
          "Business training",
          "Business education",
          "Ancillary tourism services",
          "VIP support",
      ],      },
      card2: {
        title: `INCENTIVE TRAVELS`,
        body: [
          "Business travels",
          "Individual and corporate",
          "Company presentation at relevant events",
          "",
          "BUSINESS SUPPORT:",
          "Business training",
          "Business education",
          "Ancillary tourism services",
          "VIP support",
      ],      },
      card3: {
        title: `CONFERENCES / SEMINARS`,
        body: [
          "Conference organization",
          "Participation in global events",
          "Company presentation at relevant events",
          "",
          "BUSINESS SUPPORT:",
          "Business training",
          "Business education",
          "Ancillary tourism services",
          "VIP support",
      ],      },
      card4: {
        title: `EXHIBITIONS/FAIRS`,
        body: [
          "Business exhibition organization",
          "Digital exhibitions (AR/VR/AI/Blockchain)",
          "Business cross art exhibitions",
          "Company presentation at relevant events",
          "",
          "BUSINESS SUPPORT:",
          "Business training",
          "Business education",
          "Ancillary tourism services",
          "VIP support",
      ],      },
    },
    button: "Request",
    headers: {
      about: "About",
      services: "Services",
      contact: "Contact",
    },
    modalWindow: {
      title: `Feedback`,
      email: `Email`,
      name: "Name",
      phone: `Phone`,
      textarea: `Message`,
      close: `Close`,
      send: `Send`,
      emailSendedTrue: `Email successfully sent`,
      emailSendedFalse: `Email NOT sent`,
    },
    smallCard:[
      'Boosting employee efficiency',
      'Enhancing company performance metrics',
      'Saving your time',
      'Providing comfort at any point in the world',
      'Making new partnerships even more accessible',
      'Brand recognition',
      'Additional targeted traffic',
      'Raising the level of qualification and personal development',
    ]
  },
  ukr: {
    intro: {
      top: `Обʼєднані рішення для ділового туризму`,
      bottom: `Зустрічі. Інсентиви. Конференції. Виставки`,
    },
    firstTitle: {
      title: `Ласкаво просимо до UVS провідного і привабливого простору для тих, хто шукає якісні послуги в сфері бізнес зустрічей, створення бизнес подій та бізнес подорожей. `,
      body: `UVS - сучасна компанія, яка надає повний спектр послуг, працює з партнерами по всьому світу, створює ефективний досвід для власників  бізнесів та їх компаній. 
Завдяки нашим інноваційним рішенням, ми прагнемо зекономити ваш час та надати додаткові можливості для зростання та успіху. Довіряючи UVS ви отримаєте високоякісний сервіс, персоналізований підхід до кожного клієнта та надійного партнера, що допоможе вам досягти ваших бізнес-цілей та розкрити повний потенціал вашої компанії.
`,
      firstSubsection: `Ми розуміємо важливість створення значущих зв'язків та встановлення довготривалих стосунків. Наша місія - сприяти позитивному діалогу між вами та оточуючим світом.`,
      secondarySubsection: `Ми запрошуємо вас до співпраці та відчути ефективний супровід UVS на кожному кроці.`,
    },
    singleText: `У UVS ми твердо віримо в силу співпраці та відкритого діалогу. Ми запрошуємо вас до співпраці та відчути ефективний супровід UVS на кожному кроці.`,
    centerText: {
      top: `Ми пропонуємо довгострокову співпрацю \“під-ключ\”`,
      bottom: `Детальнішу інформацію про співпрацю ви можете отримати, звернувшись до нас`,
    },
    cardProduct: {
      title: `Робимо під ключ `,
      card1: {
        title: `БІЗНЕС ЗУСТРІЧІ`,
        body: [
          "Організація зустрічей",
          "індивідуальні та корпоративні",
          "",
          "БІЗНЕС ПІДТРИМКА:",
          "Бізнес тренінги",
          "Бізнес навчання",
          "Супутні туристичні послуги",
          "VIP-підтримка",
      ],
      },
      card2: {
        title: `ІНСЕНТИВНІ ПОДОРОЖІ`,
        body: [
          "Бізнес подорожі",
          "Індивідуальні та корпоративні",
          "Презентація компанії на відповідному заході",
          "",
          "БІЗНЕС ПІДТРИМКА:",
          "Бізнес тренінги",
          "Бізнес навчання",
          "Супутні туристичні послуги",
          "VIP-підтримка",
      ],
      },
      card3: {
        title: `КОНФЕРЕНЦІЇ/СЕМІНАРИ`,
        body: [
          "Організації конференцій",
          "Організація участі у глобальних подіях",
          "Презентація компанії на відповідному заході",
          "",
          "БІЗНЕС ПІДТРИМКА:",
          "Бізнес тренінги",
          "Бізнес навчання",
          "Супутні туристичні послуги",
          "VIP-підтримка",
      ],
      },
      card4: {
        title: `ВИСТАВКИ/ЯРМАРКИ`,
        body: [
          "Організація виставок для бізнесу",
          "Цифрові виставки (AR/VR/AI/Blockchain)",
          "Виставки business cross art",
          "Презентація компанії на відповідному заході",
          "",
          "БІЗНЕС ПІДТРИМКА:",
          "Бізнес тренінги",
          "Бізнес навчання",
          "Супутні туристичні послуги",
          "VIP-підтримка",
      ],
    },
    },
    button: "Запросити",
    headers: {
      about: "Про нас",
      services: "Послуги",
      contact: "Контакти",
    },
    modalWindow: {
      title: `Зворотній зв'язок`,
      name: "Ім'я",
      email: `Email`,
      phone: `Телефон`,
      textarea: `Повідомлення`,
      close: `Закрити`,
      send: `Надіслати`,
      emailSendedTrue: `Електронна пошта успішно надіслана`,
      emailSendedFalse: `Електронна пошта НЕ надіслана`,
    },
    smallCard:[
      'Піднімаємо ефективність співробітників',
      'Піднімаємо показники компаній',
      'Економимо ваш час',
      'Забезпечуємо комфорт в будь-якій точці світу',
      'Нові партнерства стають ще більш доступними',
      'Впізнаваність бренду',
      'Додатковий цільовий трафік',
      'Підіймаємо рівень кваліфікації та особистого розвитку',
    ]
  },
  rus: {
    intro: {
      top: `Объеденённые решения для делового туризма`,
      bottom: `Встречи. Инсентив-туры. Конференции. Выставки`,
    },
    firstTitle: {
      title: `Добро пожаловать в UVS, ведущее и привлекательное пространство для тех, кто ищет качественные услуги в сфере бизнес встреч, создания бизнес событий и бизнес путешествий.`,
      body: `UVS - современная компания, которая предлагает полный спектр услуг, работает с партнерами по всему миру, создает эффективный опыт для владельцев бизнесов и их компаний. Благодаря нашим инновационным решениям, мы стремимся сэкономить ваше время и предоставить дополнительные возможности для роста и успеха. Доверяя UVS, вы получите высококачественный сервис, персонализированный подход к каждому клиенту и надежного партнера, который поможет вам достичь ваших бизнес-целей и раскрыть полный потенциал вашей компании.`,
      firstSubsection: `Мы понимаем важность создания значимых связей и установления долгосрочных отношений. Наша миссия - способствовать позитивному диалогу между вами и окружающим миром.`,
      secondarySubsection: `Мы предлагаем долгосрочное сотрудничество "под ключ". Детальную информацию о сотрудничестве вы можете получить, обратившись к нам.`,
    },
    singleText: `В UVS мы твердо верим в силу сотрудничества и открытого диалога. Мы приглашаем вас к сотрудничеству и ощутить эффективное сопровождение UVS на каждом шагу.`,
    centerText: {
      top: `Мы предлагаем долгосрочное сотрудничество \“под ключ\”.`,
      bottom: `Детальную информацию о сотрудничестве вы можете получить, обратившись к нам.`,
    },
    cardProduct: {
      title: `Делаем под ключ`,
      card1: {
        title: `БИЗНЕС ВСТРЕЧИ`,
        body: [
          "Организация встреч",
          "Индивидуальные и корпоративные",
          "",
          "БИЗНЕС ПОДДЕРЖКА:",
          "Бизнес тренинги",
          "Бизнес обучение",
          "Сопутствующие туристические услуги",
          "VIP-поддержка",
      ],
      },
      card2: {
        title: `ИНСЕНТИВ ПУТЕШЕСТВИЯ`,
        body: [
          "Бизнес путешествия",
          "Индивидуальные и корпоративные",
          "Презентация компании на соответствующем мероприятии",
          "",
          "БИЗНЕС ПОДДЕРЖКА:",
          "Бизнес тренинги",
          "Бизнес обучение",
          "Сопутствующие туристические услуги",
          "VIP-поддержка",
      ],
      },
      card3: {
        title: `КОНФЕРЕНЦИИ/СЕМИНАРЫ`,
        body: [
          "Организация конференций",
          "Организация участия в глобальных событиях",
          "Презентация компании на соответствующем мероприятии",
          "",
          "БИЗНЕС ПОДДЕРЖКА:",
          "Бизнес тренинги",
          "Бизнес обучение",
          "Сопутствующие туристические услуги",
          "VIP-поддержка",
      ],
      },
      card4: {
        title: `ВЫСТАВКИ/ЯРМАРКИ`,
        body: [
          "Организация выставок для бизнеса",
          "Цифровые выставки (AR/VR/AI/Blockchain)",
          "Выставки business cross art",
          "Презентация компании на соответствующем мероприятии",
          "",
          "БИЗНЕС ПОДДЕРЖКА:",
          "Бизнес тренинги",
          "Бизнес обучение",
          "Сопутствующие туристические услуги",
          "VIP-поддержка",
      ],
    },
    },
    button: "Запросить",
    headers: {
      about: "Про нас",
      services: "Услуги",
      contact: "Контакты",
    },
    modalWindow: {
      title: `Обратная связь`,
      name: "Имя",
      email: `Email`,
      phone: `Телефон`,
      textarea: `Сообщение`,
      close: `Закрыть`,
      send: `Отправить`,
      emailSendedTrue: `Электронная почта успешно отправлена`,
      emailSendedFalse: `Электронная почта не отправлена`,
    },
    smallCard:[
      'Повышаем эффективность сотрудников',
      'Повышаем показатели компаний',
      'Экономим ваше время',
      'Обеспечиваем комфорт в любой точке мира',
      'Новые партнерства становятся еще более доступными',
      'Узнаваемость бренда',
      'Дополнительный целевой трафик',
      'Повышаем уровень квалификации и личностного развития',
    ]
  },
  

};

export default LANGUAGE;
